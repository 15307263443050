/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from 'react'
import { KTIcon } from '../../../../helpers'
import { Column, useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'
import moment from 'moment'
import ModalRouteInfo from '../modal/ModalRouteInfo'
import { axiosClient, axiosClientAuth } from '../../../../../app/utils'
import { DatePicker } from 'antd'
import axios from 'axios'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

type Props = {
  className: string
  scrubbingLogsPeidFilter?: any
  uuid?: any
}

type columnType = {
  header: string
  content: string
  created_at: Date
  sent_to: string
  dlr_code: string
  dispatch_status: string
  remark: string
  senderid: string
  message: string
  contact: string
  scrubbing_route: Array<any>
  route_name: string
}

type ItemType = {
  route_name: string
  scrubbing_route: Array<any>
}

const TablesWidgetScrubbingLogs: React.FC<Props> = ({ className, scrubbingLogsPeidFilter = null, uuid = null }) => {
  const [show, setShow] = useState(false)
  const [currentItem, setCurrentItem] = useState<ItemType | null>(null)
  const [mData, setMData] = useState({
    results: [],
    count: 0,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [canGoBack, setCanGoBack] = useState(false);
  const [canGoNext, setCanGoNext] = useState(true);
  const [noOfPages, setNoOfPages] = useState(0);

  const onNextPage = () => setCurrentPage(currentPage + 1);
  const onPrevPage = () => setCurrentPage(currentPage - 1);
  const onPageSelect = (pageNo) => setCurrentPage(pageNo);
  const onPageSizeChange = (size) => setPageSize(size);

  console.log(canGoBack, canGoNext, "Aditya")

  useEffect(() => {
    if (noOfPages === currentPage) {
      setCanGoNext(false);
    } else {
      setCanGoNext(true);
    }
    if (currentPage === 1) {
      setCanGoBack(false);
    } else {
      setCanGoBack(true);
    }
  }, [noOfPages, currentPage]);

  const getData = (startDate: any = null, endDate: any = null) => {
    let mURL = `/main/get-scrubbing-logs?page=${currentPage}&page_size=${pageSize}`;
    if (scrubbingLogsPeidFilter) {
      mURL += `&pe_id=${scrubbingLogsPeidFilter}`
    }
    if(uuid){
      mURL += `&uuid=${uuid}`
    }
    if (startDate && endDate) {
      mURL += `&start_date=${startDate}&end_date=${endDate}`
    }
    axiosClientAuth
      .get(mURL)
      .then((res) => {
        setMData(res.data.data);
        setNoOfPages(Math.ceil(res.data?.data?.count / pageSize))
      })
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    getData();
  }, [currentPage, pageSize, scrubbingLogsPeidFilter])

  const columns: ReadonlyArray<Column<columnType>> = useMemo(
    () => [
      { Header: 'HEADER', accessor: 'senderid' },
      {
        Header: 'CONTENT',
        accessor: 'message',
      },
      {
        Header: 'DATE',
        accessor: 'created_at',
        Cell: ({ ...props }) => {
          return (
            <div className='w-100px'>
              <span>{moment(props.value).format('DD MMM YYYY')}</span>
              <span className='d-block text-muted fw-semibold'>
                {moment(props.value).format('LT')}
              </span>
            </div>
          )
        },
      },
      { Header: 'SENT TO', accessor: 'contact' },
      {
        Header: 'CODE',
        accessor: 'dlr_code',
        Cell: ({ ...props }) => {
          return props.row.original.dispatch_status == 'SUCCESS_WITH_WARNING' ? (
            <span className='badge badge-light-warning fw-bold px-4 py-3'>
              {props.value}
              <OverlayTrigger
                placement='top'
                overlay={
                  <Tooltip id='tooltip-myprofile-email'>{props.row.original.remark}</Tooltip>
                }
              >
                <i className='fas fa-exclamation-circle ms-1 fs-7' />
              </OverlayTrigger>
            </span>
          ) : props.row.original.dispatch_status == 'SUCCESS' ? (
            <span className='badge badge-light-success fw-bold px-4 py-3'>
              {props.value}
              <OverlayTrigger
                placement='top'
                overlay={
                  <Tooltip id='tooltip-myprofile-email'>{props.row.original.remark}</Tooltip>
                }
              >
                <i className='fas fa-exclamation-circle ms-1 fs-7' />
              </OverlayTrigger>
            </span>
          ) : (
            <span className='badge badge-light-danger fw-bold px-4 py-3'>
              {props.value}
              <OverlayTrigger
                placement='top'
                overlay={
                  <Tooltip id='tooltip-myprofile-email'>{props.row.original.remark}</Tooltip>
                }
              >
                <i className='fas fa-exclamation-circle ms-1 fs-7' />
              </OverlayTrigger>
            </span>
          )
        },
      },
      {
        Header: 'STATUS',
        accessor: 'dispatch_status',
        Cell: ({ ...props }) => {
          return props.value == 'SUCCESS_WITH_WARNING' ? (
            <span className='badge badge-light-warning fw-bold px-4 py-3'>Success</span>
          ) : props.value == 'SUCCESS' ? (
            <span className='badge badge-light-success fw-bold px-4 py-3'>Success</span>
          ) : props.value == 'WAITING' ? (
            <span className='badge badge-light-info fw-bold px-4 py-3'>Waiting</span>
          ) : (
            <span className='badge badge-light-danger fw-bold px-4 py-3'>Failed</span>
          )
        },
      },
      {
        Header: 'VIEW',
        Cell: ({ ...props }) => {
          return (
            <>
              <button
                className='btn btn-light'
                onClick={() => {
                  setShow(true)
                  setCurrentItem(props.row?.original as ItemType)
                }}
              >
                View
              </button>
            </>
          )
        },
      },
    ],
    []
  )

  const scrubbingLogsTable = useTable(
    {
      columns,
      data: mData?.results,
      manualPagination: true
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { RangePicker } = DatePicker

  const onDateChange = (_, val) => {
    if (val.length > 0 && val[0] != '') {
      const startDate = moment(new Date(val[0])).format('DD-MM-YYYY')
      const endDate = moment(new Date(val[1])).format('DD-MM-YYYY')
      getData(startDate, endDate);
      // setfilteredData(
      //   scrubbingLogs.filter((item) => {
      //     const routeDate = moment(new Date(item?.created_at)).format('DD-MM-YYYY')
      //     const startDate = moment(new Date(val[0])).format('DD-MM-YYYY')
      //     const endDate = moment(new Date(val[1])).format('DD-MM-YYYY')

      //     return routeDate >= startDate && routeDate <= endDate
      //   })
      // )
    } else {
      getData();
      // setfilteredData(scrubbingLogs)
    }
  }

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Message Scrubbing Logs</span>
          <span className='text-muted fw-semibold fs-7'>
            {mData?.count} Sent this month
          </span>
        </h3>
        <div className='d-flex align-items-center'>
          <RangePicker
            onChange={onDateChange}
            className='bg-gray-100 border-0 text-white w-100'
            style={{ height: '35px' }}
          />
          <select
            className='form-select form-select-sm form-select-solid w-auto ms-3'
            value={scrubbingLogsTable.headers[5].filterValue}
            onChange={(e) => scrubbingLogsTable.headers[5].setFilter(e.target.value)}
          >
            {['All', 'success', 'pending', 'failed'].map((item) => (
              <option value={item == 'All' ? '' : item}>{item}</option>
            ))}
          </select>
          <div className='d-flex align-items-center position-relative my-1 ms-2'>
            <KTIcon iconName='magnifier' className='text-white-500 position-absolute ms-2' />
            <input
              type='text'
              id='kt_filter_search'
              className='form-control form-control-solid form-select-sm w-150px ps-9'
              placeholder='Search Order'
              value={scrubbingLogsTable.state.globalFilter}
              onChange={(e) => scrubbingLogsTable.setGlobalFilter(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className='card-body pt-3'>
        <div className='table-responsive'>
          <table
            {...scrubbingLogsTable.getTableProps()}
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          >
            <thead>
              {scrubbingLogsTable.headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      className={column.render('Header') == 'ACTION' ? 'w-200px' : ''}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <span className='text-gray-400 fs-7'>{column.render('Header')}</span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <KTIcon iconName='down' className='text-white ms-1' />
                        ) : (
                          <KTIcon iconName='up' className='text-white ms-1' />
                        )
                      ) : (
                        ''
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...scrubbingLogsTable.getTableBodyProps()}>
              {(scrubbingLogsTable.page.length > 0 &&
                scrubbingLogsTable.page.map((row) => {
                  scrubbingLogsTable.prepareRow(row)
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td className='fw-bold' {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                  )
                })) || (
                  <tr>
                    <td colSpan={5} className='text-center'>
                      <span className='fw-bold'>No data available in table</span>
                    </td>
                  </tr>
                )}
            </tbody>
          </table>
          <div className='d-flex justify-content-between align-items-center mt-5'>
            <div>
              <select
                className='form-select form-select-sm form-select-solid'
                value={pageSize}
                onChange={(e) => onPageSizeChange(parseInt(e.target.value))}
              >
                {[10, 25, 50, 100].map((item) => (
                  <option>{item}</option>
                ))}
              </select>
            </div>
            <div>
              <span>
                Page{' '}
                <strong>
                  {currentPage} of{' '}
                  {Math.ceil(mData?.count / pageSize)}
                </strong>
              </span>
            </div>
            <div>
              <button
                className='btn btn-sm'
                onClick={onPrevPage}
                disabled={!canGoBack}
              >
                <KTIcon iconName='left' className='text-gray fs-1 me-5' />
              </button>
              <button
                className='btn btn-sm'
                onClick={onNextPage}
                disabled={!canGoNext}
              >
                <KTIcon iconName='right' className='text-gray fs-1 me-5' />
              </button>
            </div>
          </div>
        </div>
      </div>

      <ModalRouteInfo
        routeData={currentItem?.scrubbing_route}
        name={currentItem?.route_name}
        show={show}
        setShow={setShow}
      />
    </div>
  )
}

export { TablesWidgetScrubbingLogs }
