import {FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {IPListWrapper} from '../pages/IPList/IPList'
import {ManageTemplateWrapper} from '../pages/ManageTemplate/ManageTemplate'
import {SMSReportWrapper} from '../pages/smsReport/SMSReport'
import {SupportCenterWrapper} from '../pages/supportCenter/Support'
import {MyProfileWrapper} from '../pages/myProfile/MyProfile'
import {RolesManagementWrapper} from '../pages/rolesManagement/RolesManagement'
import {UserManagementWrapper} from '../pages/userManagement/UserManagement'
import OperatorDataWrapper from '../pages/operatorData/OperatorData'
import OperatorSummaryWrapper from '../pages/operatorData/OperatorSummary'
import MyIpWrapper from '../pages/myIp/MyIp'
import {URLApprovalWrapper} from '../pages/urlApproval/URLApproval'
import BulkReport from '../pages/BulkReport/BulkReport'

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='urlapproval' element={<URLApprovalWrapper />} />
        <Route path='managetemplate' element={<ManageTemplateWrapper />} />
        <Route path='operatordata' element={<OperatorDataWrapper />} />
        <Route path='operatorsummary' element={<OperatorSummaryWrapper />} />
        <Route path='iplist' element={<IPListWrapper />} />
        <Route path='smsreport' element={<SMSReportWrapper />} />
        <Route path='support' element={<SupportCenterWrapper />} />
        <Route path='rolesmanagement' element={<RolesManagementWrapper />} />
        <Route path='usermanagement' element={<UserManagementWrapper />} />
        <Route path='myprofile' element={<MyProfileWrapper />} />
        <Route path='myip' element={<MyIpWrapper />} />
        <Route path='bulk-report' element={<BulkReport />} />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
