import React, { useEffect, useMemo, useState } from 'react'
import { PageLink, PageTitle, useLayout } from '../../../_metronic/layout/core'
import { KTIcon } from '../../../_metronic/helpers'
import { Column, useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'
import clsx from 'clsx'
import moment from 'moment'
import { axiosClientAuth } from '../../utils'
import Swal from 'sweetalert2'

const myBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'My IP',
    path: '/myip',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export default function BulkReport() {

  const { setRightTolbar } = useLayout()

  useEffect(() => {
    setRightTolbar('')
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={myBreadCrumbs}>Bulk Report</PageTitle>

      <div className='row'>
        <div className='col-12'>
          <TableWidgeMytIp />
        </div>
      </div>
    </>
  )
}

const TableWidgeMytIp = () => {
  const [data, setData] = useState([])
  const { setCurrentBulkData } = useLayout();

  const getMyData = () => {
    axiosClientAuth
      .get('/admin/bulk-ip-upload')
      .then((res) => setData(res.data.data))
      .catch((error) => console.log(error))
  }

  useEffect(() => {
    getMyData()
  }, [])

  const columns: ReadonlyArray<Column<any>> = useMemo(
    () => [
      {
        Header: '#',
        Cell: ({ ...props }) => {
          return (
            <>
              <span>{props.row.index + 1}</span>
            </>
          )
        },
      },
      {
        Header: 'DATE',
        accessor: 'created_at',
        Cell: ({ ...props }) => {
          return (
            <>
              <span>{moment(props.value).format('DD MMM YYYY')}</span>
              <span className='d-block text-muted fw-semibold'>
                {moment(props.value).format('LT')}
              </span>
            </>
          )
        },
      },
      {
        Header: 'FileName',
        accessor: 'file_name',
      },
      {
        Header: 'Duplicate',
        accessor: 'duplicate_count'
      },
      {
        Header: 'Completed',
        accessor: 'completed_percentage',
        Cell: ({ ...props }) => (
          <>{props.value}%</>
        )
      },
      {
        Header: 'Req. Status',
        accessor: 'status',
        Cell: ({ ...props }) =>
          props.value == 'completed' ? (
            <span className='badge badge-light-success fw-bold px-4 py-3'>Completed</span>
          ) : (
            <span className='badge badge-light-danger fw-bold px-4 py-3'>Error</span>
          ),
      },
      {
        Header: 'ACTION',
        Cell: ({ ...props }) => {
          return (
            <>
              <button
                className='btn h-35px w-75px btn-icon btn btn-light-primary me-3'
                onClick={() => setCurrentBulkData(props.row.original)}
                id='kt_drawer_bulk_ip_toggle'
              // disabled={Boolean(props?.row?.original?.status)}
              >
                View
              </button>
            </>
          )
        },
      },
    ],
    []
  )

  const table = useTable(
    {
      columns: columns as readonly Column<object>[],
      data: data,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  return (
    <div className='card card-flush'>
      <div className='card-header mt-5'>
        <div className='card-title flex-column'>
          <h3 className='fw-bold mb-1'>Bulk Report</h3>
          <div className='fs-6 text-gray-400'>Total {data.length} Request</div>
        </div>
        <div className='d-flex align-items-center'>
          <div className='d-flex align-items-center position-relative my-1 ms-2'>
            <KTIcon iconName='magnifier' className='text-white-500 position-absolute ms-2' />
            <input
              type='text'
              id='kt_filter_search'
              className='form-control form-control-solid form-select-sm w-150px ps-9'
              placeholder='Search File'
              value={table.state.globalFilter}
              onChange={(e) => table.setGlobalFilter(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className='card-body pt-3'>
        <div className='table-responsive'>
          <table
            {...table.getTableProps()}
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          >
            <thead>
              {table.headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={`myip-header-${index}`}>
                  {headerGroup.headers.map((column) => (
                    <th
                      className={column.render('Header') == 'ACTION' ? 'w-200px' : ''}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <span className='text-gray-400 fs-7'>{column.render('Header')}</span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <KTIcon iconName='down' className='text-white ms-1' />
                        ) : (
                          <KTIcon iconName='up' className='text-white ms-1' />
                        )
                      ) : (
                        ''
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...table.getTableBodyProps()}>
              {(table.page.length > 0 &&
                table.page.map((row) => {
                  table.prepareRow(row)
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td className='fw-bold' {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                  )
                })) || (
                  <tr>
                    <td colSpan={5} className='text-center'>
                      <span className='fw-bold'>No data available in table</span>
                    </td>
                  </tr>
                )}
            </tbody>
          </table>
          <div className='d-flex justify-content-between align-items-center mt-5'>
            <div>
              <select
                className='form-select form-select-sm form-select-solid'
                value={table.state.pageSize}
                onChange={(e) => table.setPageSize(parseInt(e.target.value))}
              >
                {[10, 25, 50, 100].map((item) => (
                  <option>{item}</option>
                ))}
              </select>
            </div>
            <div>
              <span>
                Page{' '}
                <strong>
                  {table.state.pageIndex + 1} of {table.pageOptions.length}
                </strong>
              </span>
            </div>
            <div>
              <button
                className='btn btn-sm'
                onClick={() => table.previousPage()}
                disabled={!table.canPreviousPage}
              >
                <KTIcon iconName='left' className='text-white fs-1 me-5' />
              </button>
              <button
                className='btn btn-sm'
                onClick={() => table.nextPage()}
                disabled={!table.canNextPage}
              >
                <KTIcon iconName='right' className='text-white fs-1 me-5' />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}